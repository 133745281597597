import palette from '../palette'
import { textStyles } from './textStyles'

export const radioStyles = {
  parts: ['input', 'control', 'label'],
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    control: {
      borderRadius: '50%',
      border: `2px solid #01010180`,
      cursor: 'pointer',

      _checked: {
        borderColor: palette.primary[500],

        bg: 'transparent',
        _hover: {
          bg: 'transparent',
          borderColor: palette.primary[500],
        },

        _focus: {
          outline: 'none',
          boxShadow: 'none',
        },

        _before: {
          w: '50%',
          h: '50%',
          borderRadius: '50%',
          bg: palette.primary[500],
        },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
    label: {
      ...textStyles.baseStyle,
      ...textStyles.variants.title,
      mb: 0,
      cursor: 'pointer',
    },
  },
  sizes: {
    md: {
      control: {
        w: '20px',
        h: '20px',
      },
      label: {
        fontSize: '14px',
      },
      icon: {
        fontSize: '0.625rem',
      },
    },
  },
}
