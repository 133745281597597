import palette from '../palette'

export const drawerStyles = {
  parts: [
    'overlay',
    'dialogContainer',
    'dialog',
    'header',
    'closeButton',
    'body',
    'footer',
  ],
  baseStyle: {
    overlay: {
      bg: palette.overlay,
    },
    dialogContainer: {},
    dialog: {
      bg: palette.white,
    },
    header: {
      px: '24px',
      color: palette.text.primary,
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '28px',
      minHeight: '66px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    closeButton: {},
    body: {
      p: 0,
    },
    footer: {},
  },
  sizes: {
    xs: {
      dialog: {
        maxWidth: '554px',
      },
    },
  },
  variants: {
    menu: {
      dialog: {
        maxWidth: '240px',
      },
    },
  },
}
