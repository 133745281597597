import palette from '../palette'

export const switchStyles = {
  parts: ['container', 'track', 'thumb', 'label'],
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    container: {},
    track: {
      h: '32px',
      w: '52px',
      p: '3px',
      bg: palette.midGray[500],
      boxSizing: 'border-box',
      _focus: {
        boxShadow: 'none',
      },
      _checked: {
        bg: palette.primary[500],
      },
    },
    thumb: {
      bg: palette.white,
      w: '26px',
      h: '26px',
      _checked: {
        transform: `translateX(20px)`,
      },
    },
  },
  sizes: {
    md: {
      container: {},
      track: {},
      thumb: {},
    },
  },
  variants: {
    circle: {
      track: {
        w: '36px',
        h: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bg: palette.lightGray[400],
        border: `1px solid ${palette.lightGray[100]}`,
        _checked: {
          border: 'none',
        },
        _invalid: {
          bg: palette.red[500],
        },
      },
      thumb: {
        display: 'none',
      },
      label: {
        color: palette.darkGray[500],
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        position: 'absolute',
        top: '6px',
        left: 0,
        w: '100%',
        m: 'auto',
        textAlign: 'center',
        cursor: 'pointer',
        _checked: {
          color: palette.white,
        },
      },
    },
  },
}
