import { accordionStyles } from './components/accordionStyles'
import { alertStyles } from './components/alertStyles'
import { avatarStyles } from './components/avatarStyles'
import { buttonStyles } from './components/buttonStyles'
import { checkboxStyles } from './components/checkboxStyles'
import { containerStyles } from './components/containerStyles'
import { dividerStyles } from './components/dividerStyles'
import { drawerStyles } from './components/drawerStyles'
import { formErrorStyles } from './components/formErrorStyles'
import { headingStyles } from './components/headingStyles'
import { inputStyles } from './components/inputStyles'
import { linkStyles } from './components/linkStyles'
import { menuStyles } from './components/menuStyles'
import { modalStyles } from './components/modalStyles'
import { numberInputStyles } from './components/numberInputStyles'
import { popoverStyles } from './components/popoverStyles'
import { radioStyles } from './components/radioStyles'
import { selectStyles } from './components/selectStyles'
import { switchStyles } from './components/switchStyles'
import { textStyles } from './components/textStyles'
import palette from './palette'

export const commonTheme = {
  styles: {
    global: {
      'html, body': {
        fontSize: 14,
        fontWeight: 400,
        letterSpacing: '0.15px',
        lineHeight: '20px',
      },
    },
  },
  fonts: {
    body: 'Inter',
  },
  colors: {
    primary: palette.text.primary,
    secondary: palette.text.secondary,
  },
  components: {
    Button: buttonStyles,
    Heading: headingStyles,
    Text: textStyles,
    Link: linkStyles,
    Checkbox: checkboxStyles,
    Radio: radioStyles,
    Switch: switchStyles,
    Input: inputStyles,
    NumberInput: numberInputStyles,
    Divider: dividerStyles,
    Avatar: avatarStyles,
    Popover: popoverStyles,
    Modal: modalStyles,
    Select: selectStyles,
    Menu: menuStyles,
    Drawer: drawerStyles,
    Accordion: accordionStyles,
    Container: containerStyles,
    Alert: alertStyles,
    FormError: formErrorStyles,
  },
}
