import { InitOptions } from 'i18next'

export const i18nConfig: InitOptions = {
  fallbackLng: 'en',
  ns: [
    'global',
    'common',
    'navigation',
    'validation',
    'login',
    'register',
    'passwordReset',
    'emailConfirm',
    'dashboard',
    'accountSettings',
    'deviceLocation',
    'notifications',
    'batterySchedule',
    'support',
    'deviceErrors',
  ],
  defaultNS: 'global',
  backend: {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  },
  detection: {
    order: [
      'querystring',
      'path',
      'subdomain',
      'localStorage',
      'sessionStorage',
      'cookie',
      'navigator',
      'htmlTag',
    ],
    caches: [],
  },
}
