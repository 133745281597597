import palette from '../palette'

const navMenuStyle = {
  color: palette.mobiBlack,
  fill: palette.midGray[300],
  fontSize: 14,
  h: '32px',
  fontWeight: 'normal',
  px: '8px',
  py: '4px',
  border: 'none',
  borderRadius: 6,
}

export const linkStyles = {
  baseStyle: {
    color: palette.link.primary.text,
    minHeight: '24px',
    lineHeight: '24px',
    fontWeight: 400,
    letterSpacing: '-0.006em',
    borderBottom: '1px solid',
    borderColor: palette.link.primary.border + '33',
    transition: 'border-color 0.3s ease-in-out',
    _hover: {
      textDecoration: 'none',
      borderColor: palette.link.primary.border,
    },
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
  },
  variants: {
    navMenu: {
      ...navMenuStyle,
      _hover: {
        fill: palette.link.selected.icon,
        color: palette.link.selected.text,
      },
    },
    navMenuSelected: {
      ...navMenuStyle,
      fontWeight: 600,
      fill: palette.link.selected.icon,
      color: palette.link.selected.text,
      bg: palette.link.selected.background,
    },
  },
}
