import palette from '../palette'
import { inputStyles } from './inputStyles'

export const containerStyles = {
  variants: {
    notificationsListTile: {
      borderTop: `1px solid ${palette.lightGray[300]}`,
      p: 0,
      m: 0,
      maxWidth: '100%',
      display: 'grid',
      gridTemplateColumns: {
        base: '24px 1fr fit-content(100%)',
        md: '24px 1fr minmax(256px, auto)',
      },
      gridTemplateAreas: '"icon main time"',
      columnGap: '24px',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    successToast: {
      cursor: 'pointer',
      width: 'auto',
      display: 'inline-grid',
      gridTemplateColumns: 'auto 1fr',
      alignItems: 'center',
      columnGap: '16px',
      p: '16px',
      pr: '24px',
      borderRadius: '6px',
      bg: palette.darkGray[300],
      boxShadow: `0px 0px 1px ${palette.button.shadow.shadow1}, 0px 1px 2px ${palette.button.shadow.shadow2}`,
      color: palette.lightGray[500],
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 600,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    richInput: {
      ...inputStyles.baseStyle.field,
      ...inputStyles.variants.outline.field,
      cursor: 'text',
      h: 'auto',
      lineHeight: 'initial',
      minHeight: '128px',
      maxHeight: '200px',
      overflowY: 'auto',
      display: 'grid',
      gridTemplateColumns: '1fr',
      p: 4,
      py: '8px',
      m: 0,
      transitionProperty: 'common',
      transitionDuration: 'normal',
    },
  },
}
