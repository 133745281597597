import palette from '../palette'

export const numberInputStyles = {
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    field: {
      color: palette.text.primary,
      height: '40px',
      lineHeight: '40px',
      fontWeight: 400,
      fontSize: 14,
      fontFamily: 'Inter',
      letterSpacing: '-0.006em',
      boxShadow: 'none !important',
      _disabled: {
        bg: palette.lightGray[500],
        color: palette.midGray[300],
        borderColor: `${palette.lightGray[400]} !important`,
      },
      _readOnly: {
        cursor: 'auto',
        borderColor: `${palette.lightGray[400]} !important`,
      },
    },

    stepper: {
      color: palette.text.primary,
      border: 'none',
      pr: '8px',
      _first: {
        pt: '4px',
      },
      _last: {
        mt: 0,
        pb: '4px',
      },
    },
  },
  sizes: {
    md: {
      h: '40px',
    },
  },
  variants: {
    outline: {
      field: {
        h: '40px',
        borderRadius: 6,
        borderColor: palette.button.secondary.border,
        _invalid: {
          borderColor: palette.input.error.border,
        },
        _focus: {
          borderColor: palette.input.focus,
        },
      },
    },
  },
}
