import palette from '../palette'

export const selectStyles = {
  parts: ['field', 'icon'],
  baseStyle: {
    field: {
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      color: palette.text.primary,
      boxShadow: 'none !important',
      _readOnly: {
        cursor: 'auto',
        borderColor: `${palette.lightGray[400]} !important`,
      },
    },
    icon: {
      w: '24px',
      h: '24px',
    },
  },
  variants: {
    outline: {
      field: {
        h: '48px',
        lineHeight: '24px',
        borderRadius: 6,
        borderColor: palette.button.secondary.border,
        _focus: {
          boxShadow: 'none',
          borderColor: 'inherit',
        },
      },
    },
  },
}
