import palette from '../palette'

export const dividerStyles = {
  baseStyle: {
    borderColor: palette.lightGray[300],
  },
  variants: {
    solid: {
      my: '16px !important',
    },
  },
}
