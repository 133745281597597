import palette from '../palette'

export const accordionStyles = {
  parts: ['container', 'button', 'panel', 'icon'],
  baseStyle: {
    container: {
      border: 'none',
    },
    button: {
      color: palette.darkGray[300],
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      p: 0,
      alignItems: 'flex-start',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'inherit',
      },
    },
    panel: {
      p: 0,
      color: palette.darkGray[200],
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    },
    icon: {
      w: '24px',
      h: '24px',
      color: palette.midDarkGray,
    },
  },
}
