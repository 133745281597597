import React, { Suspense, useState } from 'react'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import ReactDOM from 'react-dom'
import { commonTheme } from './app/themes/common'
import { i18nConfig } from './configs/i18n.config'
import i18n, { initializeI18n } from './i18n'
import reportWebVitals from './reportWebVitals'

if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_MOCK_SERVER === 'true'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { serverMock } = require('./api/mocks/index')
  serverMock.start({
    quiet: true,
    onUnhandledRequest: () => void 0,
  })
}

initializeI18n(i18nConfig).then(
  (_t) => {
    // Initialize other functionality with loaded translations
  },
  (error: Error | string) => {
    // eslint-disable-next-line no-console
    console.error(error)
  },
)

const LocaleAppProvider = React.memo(() => {
  const [initialized, setInitialized] = useState(false)
  i18n.on('initialized', () => {
    setInitialized(true)
  })

  const App = React.lazy(() => import('./app/App'))
  const theme = extendTheme(commonTheme)

  if (initialized) {
    return (
      <React.StrictMode>
        <Suspense fallback={<div />}>
          <ChakraProvider resetCSS theme={theme}>
            <App />
          </ChakraProvider>
        </Suspense>
      </React.StrictMode>
    )
  }
  return null
})

ReactDOM.render(<LocaleAppProvider />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
