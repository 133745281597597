const primaryText = '#252C32' as const

const primary = {
  50: '#F1FCEB',
  100: '#E0FAD2',
  500: '#389706',
} as const

const commonColors = {
  red: {
    100: '#FFEFEB',
    500: '#F76659',
    600: '#F2271C',
  },
  purple: {
    300: '#BA68C8',
  },
  green: {
    500: '#47D16C',
  },
  yellow: {
    600: '#F8C51B',
  },
  deepOrange: {
    300: '#FF8A65',
  },
  lightGray: {
    100: '#D5DADD',
    300: '#E5E9EB',
    400: '#EEF0F2',
    500: '#F6F8F9',
  },
  midGray: {
    100: '#5B6871',
    200: '#6E7C87',
    300: '#84919A',
    400: '#9AA6AC',
    500: '#B0BABF',
  },
  darkGray: {
    100: '#1A2024',
    200: primaryText,
    300: '#303940',
    500: '#48535B',
  },
  midDarkGray: '#6E7C87',
} as const

const palette = {
  ...commonColors,
  white: '#fff',
  black: '#000',
  mobiBlack: '#1E1E1E',
  disabled: {
    background: commonColors.lightGray[300],
    text: commonColors.midDarkGray,
  },
  primary,
  overlay: '#0004',
  text: {
    primary: primaryText,
    secondary: commonColors.midGray[300],
    error: commonColors.red[600],
  },
  button: {
    primary: {
      background: primary[500],
    },
    secondary: {
      text: primaryText,
      border: '#DDE2E4',
      background: commonColors.lightGray[500],
    },
    shadow: {
      shadow1: '#1A202452',
      shadow2: '#5B687152',
      focus: '#9FDC7E',
    },
  },
  input: {
    icon: commonColors.midGray[500],
    focus: 'blue.500',
    error: {
      border: commonColors.red[500],
    },
  },
  link: {
    primary: {
      text: '#498C26',
      border: primary[500],
    },
    selected: {
      icon: primary[500],
      text: '#498C26',
      background: '#E9FFDE',
    },
  },
  chart: {
    grid: commonColors.lightGray[300],
  },
} as const

export default palette
