import palette from '../palette'

export const menuStyles = {
  parts: ['button', 'list', 'item', 'groupTitle', 'command', 'divider'],
  baseStyle: {
    button: {
      h: '48px',
    },
    list: {
      p: 0,
      bg: palette.white,
      zIndex: 99,
    },
    item: {
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: 14,
      lineHeight: '22px',
      color: palette.text.primary,
      pl: '24px',
    },
    groupTitle: {},
    command: {},
    divider: {},
  },
}
