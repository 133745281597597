import palette from '../palette'

export const formErrorStyles = {
  baseStyle: {
    text: {
      color: palette.text.error,
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
}
