import palette from '../palette'

export const popoverStyles = {
  parts: ['popper', 'content', 'header', 'body', 'footer', 'arrow'],
  baseStyle: {
    popper: {
      boxShadow: `0px 0px 1px ${palette.button.shadow.shadow1}, 0px 1px 2px ${palette.button.shadow.shadow2}`,
    },
    content: {
      bg: palette.white,
      border: 'none',
      _focus: {
        outline: 0,
        boxShadow: 'none',
      },
    },
    body: {
      p: '24px',
    },
  },
}
