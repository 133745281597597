import palette from '../palette'

export const headingStyles = {
  baseStyle: {
    fontWeight: 600,
    color: palette.text.primary,
    fontFamily: 'Inter',
    letterSpacing: '-0.019em',
  },
  sizes: {
    md: {
      fontSize: 24,
      minHeight: '32px',
      lineHeight: '32px',
    },
  },
  variants: {
    pageTitle: {
      fontWeight: 700,
      fontSize: 25,
      color: palette.darkGray[300],
    },
  },
}
