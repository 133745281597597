import palette from '../palette'
import { textStyles } from './textStyles'

export const checkboxStyles = {
  parts: ['icon', 'control', 'label'],
  defaultProps: {
    size: 'md',
  },
  baseStyle: {
    icon: {
      color: palette.white,
    },
    control: {
      borderRadius: 4,
      border: `1px solid`,
      borderColor: palette.midGray[500],
      bg: palette.lightGray[500],

      _checked: {
        border: 'none',

        bg: palette.button.primary.background,
        _hover: {
          bg: palette.button.primary.background,
        },

        _focus: {
          outline: 'none',
          boxShadow: `0 0 0 4px ${palette.button.shadow.focus}`,
        },
      },
      _focus: {
        boxShadow: 'none',
      },
      _invalid: {
        border: `2px solid ${palette.red[500]}`,
        _hover: {
          border: `2px solid ${palette.red[500]}`,
        },
      },
    },
    label: {
      ...textStyles.baseStyle,
      ...textStyles.variants.title,
      mb: 0,
      ml: '8px',
    },
  },
  sizes: {
    md: {
      control: {
        w: '20px',
        h: '20px',
      },
      label: {
        fontSize: '14px',
      },
      icon: {
        fontSize: '0.625rem',
      },
    },
  },
  variants: {
    compact: {
      control: {
        w: '24px',
        h: '24px',
      },
      label: {
        fontWeight: 700,
        fontSize: '14px',
      },
      icon: {
        w: '24px',
        h: '24px',
      },
    },
  },
}
