import palette from '../palette'

export const modalStyles = {
  parts: [
    'overlay',
    'dialogContainer',
    'dialog',
    'header',
    'closeButton',
    'body',
    'footer',
  ],
  baseStyle: {
    overlay: {},
    dialogContainer: {},
    dialog: {
      p: '24px',
      bg: palette.white,
      border: 'none',
    },
    header: {
      color: palette.darkGray[300],
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '26px',
      p: 0,
    },
    closeButton: {},
    body: {
      p: 0,
      my: '24px',
      color: palette.midGray[100],
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
    },
    footer: {
      p: 0,
    },
  },
}
