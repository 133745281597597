import palette from '../palette'

export const buttonStyles = {
  defaultProps: {
    variant: 'simple',
  },
  baseStyle: {
    borderRadius: '6px',
    px: '24px',
    py: '16px',
    bg: palette.white,
    borderWidth: 1,
    borderColor: palette.button.secondary.border,
    textColor: palette.button.secondary.text,
    fontWeight: 400,
    fontSize: 14,
    letterSpacing: '-0.6px',
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    _focus: {
      outline: 'none',
      boxShadow: 'none',
    },
    _disabled: {
      opacity: 0.5,
    },
    _hover: {
      bg: palette.white,
    },
    boxShadow: 'none',
  },
  variants: {
    simple: {
      bg: palette.white,
    },
    submit: {
      height: '48px',
      lineHeight: '24px',
      fontWeight: 600,
      bg: palette.button.primary.background,
      color: palette.lightGray[500],
      borderWidth: 0,
      boxShadow: `0px 0px 1px ${palette.button.shadow.shadow1}, 0px 1px 2px ${palette.button.shadow.shadow2}`,
      _hover: {
        bg: palette.button.primary.background,
        _disabled: {
          bg: palette.button.primary.background,
        },
      },
    },
    iconButton: {
      w: '24px',
      h: '24px',
      px: 0,
      minWidth: 'auto',
      border: 'none',
      outline: 'none',
      _hover: {
        bg: palette.button.secondary.background,
        _disabled: {
          bg: palette.button.secondary.background,
        },
      },
    },
    primary: {
      minHeight: '48px',
      bg: palette.button.primary.background,
      color: palette.white,
      fill: palette.white,
      borderWidth: 0,
      boxShadow: `0px 0px 1px ${palette.button.shadow.shadow1}, 0px 1px 2px ${palette.button.shadow.shadow2}`,
      _hover: {
        bg: palette.button.primary.background,
        _disabled: {
          bg: palette.button.primary.background,
        },
      },
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
    },
    secondary: {
      minHeight: '48px',
      bg: palette.lightGray[300],
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      borderWidth: 0,
      _hover: {
        bg: palette.lightGray[300],
      },
    },
    groupButton: {
      color: palette.text.primary,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      bg: 'transparent',
      border: 'none',
      boxShadow: 'none',
      _hover: {
        bg: 'transparent',
      },
    },
    groupButtonSelected: {
      bg: palette.button.primary.background,
      color: palette.white,
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '24px',
      border: 'none',
      boxShadow: 'none',
      cursor: 'auto',
      _hover: {
        bg: palette.button.primary.background,
      },
    },
  },
}
