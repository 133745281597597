import palette from '../palette'

export const avatarStyles = {
  parts: ['container', 'label'],
  baseStyle: {
    label: {
      fontFamily: 'Inter',
      fontWeight: 600,
      color: palette.text.primary,
    },
    container: { bg: palette.lightGray[300] },
  },
  sizes: {
    sm: {
      label: {
        fontSize: '10px',
        lineHeight: '16px',
        fontWeight: 600,
      },
      container: {
        w: '24px',
        h: '24px',
      },
    },
  },
}
